import "../styles/globals.css";
import "@fontsource/nunito";
import type { AppProps } from "next/app";
import { AppContextProvider } from "../context/useAppContext";
import { useEffect, useState } from "react";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import { Box, CssBaseline, responsiveFontSizes, styled } from "@mui/material";
import { theme } from "../theme";
import { useRouter } from "next/router";
import { SWRConfig } from "swr";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../components/error";
import { IError } from "../interfaces/error";
import _ from "lodash";
import { NotificationContext, NotificationProvider } from "../components/notifications/notification-provider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import createEmotionCache from "../utils/functions/create-emotion-cache";
import { LoaderProvider } from "../utils/hooks/loader/loader-context";
import { I18nextProvider } from "react-i18next";
import i18n from "../utils/translations/i18n";

const responsiveTheme = responsiveFontSizes(theme);

const RootLayout = styled("div")(({ theme }: any) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  fontFamily: "Nunito, sans-serif",
}));
const clientSideEmotionCache = createEmotionCache();

export default function App({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: AppProps & { emotionCache: any }) {
  const router = useRouter();
  const lang = (router.query?.lang as string) ?? "en";

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang as string);
    }
  }, [lang]);

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={responsiveTheme}>
        <CssBaseline />
        <I18nextProvider i18n={i18n}>
          <LoaderProvider>
            <NotificationProvider>
              <NotificationContext.Consumer>
                {({ showNotification }) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <SWRConfig
                      value={{
                        shouldRetryOnError: false,
                        onError: (error: IError, key) => {},
                      }}>
                      <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <AppContextProvider context={{ apiKey: process.env.NEXT_PUBLIC_API_KEY }}>
                          {_.isEmpty(process.env.NEXT_PUBLIC_API_KEY) ? (
                            <></>
                          ) : (
                            <RootLayout>
                              <Component {...pageProps} />
                            </RootLayout>
                          )}
                        </AppContextProvider>
                      </ErrorBoundary>
                    </SWRConfig>
                  </LocalizationProvider>
                )}
              </NotificationContext.Consumer>
            </NotificationProvider>
          </LoaderProvider>
        </I18nextProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
