export const styles = {
  Modal: {
    backdropFilter: "blur(10px)",
    backgroundColor: "transparent",
  },
  Dialog: {
    // bgcolor: "#161616",
    // border: "2px solid white",
    boxShadow: 24,
    color: "white",
    py: 3,
    px: 5,
    // borderRadius: "2rem",
    textAlign: "center",
  },
  Error: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    bgcolor: "#161616",
    border: "10px solid white",
    boxShadow: 24,
    p: 6,
    textAlign: "center",
  },
  Notification: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    bgcolor: "#161616",
    border: "10px solid white",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  },
};
