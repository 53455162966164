import * as React from "react";
import { CircularProgress, CssBaseline, Dialog, DialogContent, useTheme } from "@mui/material";
import { styles } from "./style";
import { LoadingModalProps } from "./types";

const LoadingModal = (props: LoadingModalProps) => {
  const theme = useTheme();
  return (
    <>
      <CssBaseline />
      <Dialog
        open={true}
        maxWidth="sm"
        sx={styles.Modal}
        componentsProps={{ backdrop: { style: { backgroundColor: "transparent" } } }}
        PaperProps={{ sx: { backgroundColor: "transparent", boxShadow: "none" } }}>
        <DialogContent sx={styles.Dialog}>
          <CircularProgress size="3rem" sx={{ m: "0.2rem", color: theme.customColors.yellow }} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoadingModal;
