import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources: {
    en: {
      translation: require("../../locales/en.json"),
    },
    es: {
      translation: require("../../locales/es.json"),
    },
    ru: {
      translation: require("../../locales/ru.json"),
    },
    de: {
      translation: require("../../locales/de.json"),
    },
    ua: {
      translation: require("../../locales/ua.json"),
    },
    fr: {
      translation: require("../../locales/fr.json"),
    },
    hi: {
      translation: require("../../locales/hi.json"),
    },
    id: {
      translation: require("../../locales/id.json"),
    },
    it: {
      translation: require("../../locales/it.json"),
    },
    ja: {
      translation: require("../../locales/ja.json"),
    },
    ka: {
      translation: require("../../locales/ka.json"),
    },
    pt: {
      translation: require("../../locales/pt.json"),
    },
    ko: {
      translation: require("../../locales/ko.json"),
    },
    tr: {
      translation: require("../../locales/tr.json"),
    },
    cn: {
      translation: require("../../locales/cn.json"),
    },
  },
});

export default i18n;
